'use strict';

var autocompleteOptions = {
  // country: 'VN',
  // postalCode: '10000'
  language: ['vi-VN'],
  componentRestrictions: { country: "vn" }
};

$(function() {
  toggleMenu($('.btn-toggle-menu'));
  toggleOrderMoreDetail('.order-more-detail');

  var inputLocation = [];
  inputLocation.push(document.querySelector('.input-location'));
  autocompleteInputLocation(inputLocation, 0);

  inputDatepicker();
  toggleChangeLocationSearch();
  clearInput();
  showModal();
});

// (function () {
//   $('.menu-wrapper').on('click', function() {
//     $('.hamburger-menu').toggleClass('animate');
//   });
// })();

// Close modal
$(document).bind('touchstart click',function(e) {
  // Close nav
  if (!$(e.target).closest("nav").length) {
    $("body").find(".menu-navigation").removeClass("active");
    $('body').removeClass('modal-showing');
  }
});

function toggleMenu(button) {
  var $button = button;
  $button.on('click', function() {
    var target = $(this).data('target');
    $('body').toggleClass('modal-showing');
    if ($(target)) {
      $(target).toggleClass('active');
    }
  })
}

function toggleOrderMoreDetail(targetEl) {
  $('input[type=radio]').on('init change', function(e) {
    var _this = this;
    var target = $(_this).data('target');
    if (target === targetEl) {
      var toggleShow = $(_this).data('show');
      toggleShow ? $(targetEl).fadeIn() : $(targetEl).fadeOut();
    } else {
      console.log('false');
    }
  });
}

function autocompleteInputLocation(inputLocation, i) {
  if (inputLocation.length > 0 && inputLocation[i]) {
    new google.maps.places.Autocomplete(inputLocation[i], autocompleteOptions);
  }
}

function inputDatepicker() {
  var $date = $('.form-control-date');
  var t = new Date();
  var today = t.getDate() + '-' + (t.getMonth() + 1) + '-' + t.getFullYear();
  $date.text(today);

  $('#dtBox').DateTimePicker({
    defaultDate: new Date(),
    minDate: new Date(),
    minTime: t.getHours() + ':' + t.getMinutes(),
    shortDayNames: ["Chủ nhật", "Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7"],
    fullDayNames: ["Chủ nhật", "Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7"],
    shortMonthNames: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
    fullMonthNames: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
    minuteInterval: 15,
    dateTimeFormat: "dd-MM-yyyy HH:mm",
    titleContentDate: "Chọn ngày",
    titleContentTime: "Chọn giờ",
    titleContentDateTime: "Chọn thời gian",
    setButtonContent: "Chọn",
    clearButtonContent: "Huỷ",
    label: {
      "year": "Năm",
      "month": "Tháng",
      "day": "Ngày",
      "hour": "Giờ",
      "minutes": "Phút",
      "seconds": "Giây",
      "meridiem": "Mili giây"
    },
    isPopup: true,
    monthYearSeparator: ",",
    buttonsToDisplay:   ["HeaderCloseButton", "SetButton"]
  });

  // Setdefault time
  var minutes = t.getMinutes();
  minutes = minutes >= 60 ? "00" : "30";
  var hours = t.getHours();
  hours = minutes == "00" ? hours + 1 : hours;
  var time = hours + ":" + minutes;

  var timeNode = $('[data-field=time]').prop("tagName");
  switch (timeNode) {
    case "INPUT":
      $(['data-field=time']).val(time);
      break;
    case "DIV":
      $('[data-field=time]').text(time);
      break;
    default:
      $('[data-field=time]').text(time);
  }
}

function toggleChangeLocationSearch() {
  var $btnToggle = $('.form-with-segment .form-toggle-value > button');
  if ($btnToggle.length > 0) {
    $btnToggle.on('click', function(e) {
      e.preventDefault();
      var status = $(this).data('toggle'); // true or false. Default false is not toggle value. True is toggled.
      var updateStatus = status ? false : true;
      $(this).data('toggle', updateStatus);

      var $segment = $(this).closest('.form-with-segment');

      if ($segment.length > 0) {
        $segment.find('.input-location').geocomplete('delete');
        var inputStart = $segment.find('.form-location-start .form-group-addon')[0];
        var inputEnd = $segment.find('.form-location-end .form-group-addon')[0];

        if (inputStart && inputEnd) {
          var terminal = $(inputStart).clone(true, true);
          $(inputStart).replaceWith($(inputEnd).clone(true, true));
          $(inputEnd).replaceWith(terminal);

          $inputLocation = $segment.find('.input-location');
          var inputLocation = [];
          inputLocation.push($inputLocation[0]);
          autocompleteInputLocation(inputLocation, 0);
        }
      }
    });
  }
}

/*
  Clear Input
  - Default: delete value of input in form group
  - Custom: delete value of input of "data-target"
*/
function clearInput() {
  var $btnClear = $('.btn-clear');
  if ($btnClear.length > 0) {
    $btnClear.click(function(e) {
      e.preventDefault();

      var _this = this;
      var $parent = $(_this).parent();

      if ($parent.hasClass('form-addon-right')) {
        var $input = $parent.prev();
        if ($input && $input.hasClass('form-control') && $input.prop("tagName") !== "SELECT") {
          var nodeName = $input[0].nodeName;
          switch (nodeName) {
            case 'INPUT':
              $input.val('');
              break;
            default:
              $input.text('');
          }
          $(_this).hide();
        }
      }
    });
  }

  // Display button clear
  var $inputField = $('.form-control');
  $inputField.each(function(index, input) {
    var nodeName = $(input).prop("tagName");
    var value = nodeName == 'INPUT' ? $(input).val() : $(input).text();
    var btnClear = $(input).parent().find('.btn-clear');

    value.length > 0 && btnClear ? $(btnClear).show() : $(btnClear).hide();
  });

  $inputField.on('keyup change', function(e) {
    var value = $(this).val() || $(this).text();
    var $btnClear = $(this).parent().find('.btn-clear');

    value == '' ?$btnClear.hide() : $btnClear.show();
  });
}

function showModal() {
  var $btnShowModal = $('.btn-show-modal');
  $btnShowModal.click(function(e) {
    e.preventDefault();

    var _this = this;
    var targetModal = $(_this).data('target');

    if (targetModal) {
      var $modal = $(targetModal);
      $modal.addClass('show');
      // $('body').addClass('show-modal');
    }
  });

  var $btnCloseModal = $('.btn-close');
  $btnCloseModal.click(function(event) {
    event.preventDefault();

    var _this = this;
    var target = $(_this).data('target');

    if (target) {
      var $modal = $(target);
      $modal.removeClass('show');
      // $('body').removeClass('show-modal');
    }
  });
}
